.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

/* Drag INICIO */
.containerDrag {
  width: 100%;
  height: 10vh;
  display: flex;
  place-content: center;
  place-items: center;
  overflow: hidden;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 30px;
}

.itemDrag {
  width: auto;
  height: auto;
  /*  background: white;*/
  border-radius: inherit;
}

/* Drag FIN */

/* ProgressBarHorizontal INICIO */
.progress-bar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 0.5vh;
  transform-origin: 0%;
}

/* ProgressBarHorizontal FIN */

/* ProgressBarVertical INICIO */
.item {
  width: inherit;
  height: inherit;
  background: #605446;
  transform-origin: 50% 100%;
}

.wrapper {
  z-index: 1;
  width: 1vw;
  height: 79vh;
  position: fixed;
  top: 50%;
  left: 0.5vw;
  transform: translateX(-0.5vw) translateY(-50%);
}

.RellenoScroll {
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: rgba(96, 84, 70, 0.2);
  border-radius: 30px;
}

/* ProgressBarVertical FIN */

.App-container {
  background-color: #FEFEFE;
  min-height: 80vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-header {
  background-color: #F3F3F3;
  min-height: 10vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  /*  border: double;
  border-style: double;
  border-color: black;
  border-width:1pxvh;*/
  box-sizing: borderBox;
}

.App-footer {
  background-color: #F3F3F3;
  min-height: 10vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  border: double;
  border-style: double;
  border-color: black;
  border-width: 1pxvh;
  box-sizing: borderBox;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.Divisor {
  height: 0.5vh;
  width: 100%;
  background-color: #605446;
  position: relative;
  /*Div hasta abajo del div INICIO*/
/*  position: absolute;*/
  bottom: 0vh;
  /*Div hasta abajo del div FINAL*/
  /* Letras centradas abajo INICIO */
  display: flex;
  align-items: end;
  justify-content: center;
  /* Letras centradas abajo FINAL */
}

.TransicionBotones {
  background-color: red;
  margin: 1vw;
  padding: 0px 1vw 0px 1vw;
  border-style: solid;
  border-width: 0.5vh;
  /*  border-color: black;
  border-top: 20px solid #3ACFD5;
  border-bottom: 20px solid #3a4ed5;*/
  border-image: linear-gradient(to bottom, #A39082 0%, #605446 100%);
  border-image-slice: 1;
  transition: background-color 0.5s ease-in-out;
}

@media (max-width: 767px) {

  /* Align text to center.*/
  .Responsivo {
    height: 6vh;
  }
}

@media (min-width: 768px) {
  .Responsivo {
    height: 8vh;
  }
}

@media (min-height: 500px) and (min-width:580px) {
  /* CSS Stuff */
}

.mientras1{
  text-align: center;
  border-style: solid;
  border-width: 0.1vw;
  border-color: #605446;
  max-height: 100vh;
  max-width:40vw;
  float: left;
}

.mientras2{
  text-align: center;
  border-style: solid;
  border-width: 0.1vw;
  border-color: blue;
  max-height: 100vh;
  max-width:40vw;
  float: left;
}

body::-webkit-scrollbar{
  display: none;
}